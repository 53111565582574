<template>
  <div class="messaging-window">
    <b-button
      variant="link"
      class="w-100 mb-2"
      @click="$emit('show-more')"
      v-if="!allLoaded && orderedMessages.length >= 10"
      >Показать ещё</b-button
    >
    <p class="text-center" v-else>Загружены все сообщения</p>
    <div id="messages" class="messages">
      <div
        :class="
          'message-item ' +
          (message.creator == 'admin'
            ? 'message-item--admin'
            : 'message-item--me')
        "
        v-for="(message, index) in orderedMessages"
        :key="index"
      >
        <div class="message-item__text">
          {{ message.text }}
          <br />
          <div class="message-item__time">
            {{ formatDateTime(message.createdAt) }}
          </div>
        </div>
      </div>
    </div>
    <textarea
      v-model="newMessage"
      @keyup.enter="submitSendMessage"
      cols="10"
      rows="2"
    ></textarea>
    <b-button
      type="submit"
      variant="success"
      class="w-100"
      @click="submitSendMessage"
      >Отправить</b-button
    >
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "MessagingWindow",
  props: {
    messages: Array,
    allLoaded: Boolean,
    chatId: String,
  },

  data() {
    return {
      newMessage: "",
    };
  },
  computed: {
    orderedMessages() {
      let messages = [...this.messages];
      return messages.sort(function (a, b) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    },
  },

  methods: {
    formatDateTime(date) {
      return (
        date &&
        new Date(date).toLocaleDateString() +
          " в " +
          new Date(date).toLocaleTimeString()
      );
    },

    submitSendMessage() {
      let message = this.newMessage.trim();

      if (message.length == 0) {
        return;
      }

      this.$emit("send-message", {
        chatId: this.chatId,
        text: message,
      });
      this.newMessage = "";
    },
  },

  watch: {
    messages() {
      setTimeout(() => {
        let messages = document.getElementById("messages");
        messages.scrollTop = messages.scrollHeight;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.messaging-window {
  padding: 15px;
  background-color: #212121;
  margin-bottom: 20px;
  border-radius: 10px;
}
.messages {
  height: 340px;
  overflow: auto;
  margin-bottom: 15px;
  * {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
  }

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #212121;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.103);
    border-radius: 5px;
    border: 3px solid #212121;
  }
}
.message-item {
  display: block;
  margin-bottom: 10px;

  &__text {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.103);
    border-radius: 10px;
    padding: 10px;
    width: auto;
  }

  &__time {
    padding-top: 3px;
    font-size: 0.8em;
    opacity: 0.8;
  }

  &--admin {
    text-align: left;
  }
  &--me {
    text-align: right;
  }
  &--me &__text {
    text-align: right;
    background-color: rgb(255, 77, 77);
  }
}
</style>
