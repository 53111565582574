<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <h1>Чат с администратором</h1>
        <MessagingWindow
          :messages="messages"
          :all-loaded="allMessageShown"
          :chat-id="adminChatId"
          v-on:show-more="loadMessages(adminChatId)"
          v-on:send-message="doSendMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MessagingWindow from "../components/MessagingWindow.vue";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "ChatAdmin",
  data() {
    return {
      allMessageShown: false,
      messages: [],
      adminChatId: "",
    };
  },
  components: { MessagingWindow },

  methods: {
    ...mapActions(["sendMessage"]),

    async doSendMessage(obj) {
      let newMessage = await this.sendMessage(obj);
      if (newMessage) {
        this.messages.push(newMessage);
      }
    },

    async loadAdminChatId() {
      try {
        const response = (
          await axios.post(`https://vp.bw2api.ru/api/v1/chat/seller/admin`)
        ).data;

        this.adminChatId = response._id;
      } catch (error) {
        console.log(error);
      }
    },

    async loadMessages(chatId) {
      try {
        let res = (
          await axios.get(
            `https://vp.bw2api.ru/api/v1/chat/message?chat=${chatId}&offset=${this.messages.length}`
          )
        ).data;
        this.messages = [...res, ...this.messages];
        if (res.length == 0) {
          this.allMessageShown = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.loadAdminChatId();
    await this.loadMessages(this.adminChatId);
  },
};
</script>
